<template>

    <div class="container-fluid">
        <div class="row lined">
            <div class="col-sm-3">
                Status
            </div>
            <div class="col-sm-9">
                <div class="radio">
                    <label>
                        <input type="radio" value="open" v-model="status" />
                        <strong>Open</strong><br />
                        This maintenance request is still ongoing
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" value="onhold" v-model="status" />
                        <strong>On Hold</strong><br />
                        This maintenance request is not resolved, but there is no further action to take now.
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" value="closed" v-model="status" />
                        <strong>Closed</strong><br />
                        This maintenance request is closed without resolution. The client is unresponsive, or there was nothing to resolve.
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" value="resolved" v-model="status" />
                        <strong>Resolved</strong><br />
                        This maintenance request is resolved. The clients issue has been solved.
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                Notes
            </div>
            <div class="col-sm-9">
                <textarea class="form-control" v-model="content" rows="4"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-dark" @click="saveMessage">Save</button>
            <button class="btn btn-std" @click="$emit('close')">Cancel</button>
        </div>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "MaintenanceRequests",
        data() {
            return {
                content:'',
                status: ''
            }
        },
        created(){
            this.status = JSON.parse(JSON.stringify(this.maintenance.status));
        },
        components:{
        },
        filters:{
        },
        methods: {
            saveMessage(){
                var data = {
                    status: this.status,
                    content: this.content
                }
                this.$emit('save', data);
            },
            closeDropdown(){
                //TODO JQUERY FIX
                // $(event.target).closest('.dropdown-menu').dropdown('toggle');
            }
        },
        props:['maintenance']
    }

</script>