<template>
    <hb-modal v-model="dialog" show-help-link size="large" title="Maintenance Request" @close="$emit('close')">
        <template v-slot:content>

        <div class="slide-out-header-title mt-2">
            <div><strong>Maintenance Request</strong></div>
            <h2 class="slide-out-tenant-heading">#{{request.id}} </h2>
        </div>
        <div class="slide-out-maintenance-header">
            <div class="slide-out-tenant-info">


                <div class="maintenance-request-status">
                    <div class="w-row">
                        <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                            <div v-if="request.RequestType">Type: {{request.RequestType.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="maintenance-request-status">
                    <div class="w-row">
                        <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                            <div>Status: <hb-status>{{request.status}}</hb-status></div>
                        </div>
                    </div>
                </div>
                <div class="maintenance-request-status">
                    <div class="w-row">
                        <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                            <div>Severity: <hb-status>{{request.severity}}</hb-status></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-out-header-right">
                <div class="w-row">
                    <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                        <div class="description">Created By</div>

                    </div>

                    <div class="column w-col w-col-6 w-col-small-8 w-col-tiny-6 data">
                        <div v-if="request.Contact && request.Lease"><strong>{{request.Contact.first}} {{request.Contact.last}}<br></strong>{{request.Lease.Unit.Address.address}} #{{request.Lease.Unit.number}}<br>{{ request.Lease.Unit.Address | formatAddress }}</div>
                    </div>
                </div>
                <div class="w-row">
                    <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                        <div>Created On</div>
                    </div>
                    <div class="column w-col w-col-6 w-col-small-8 w-col-tiny-6 data">
                        <div>{{request.date | formatDateTime}}</div>
                    </div>
                </div>
                <div class="w-row">
                    <div class="column w-col w-col-6 w-col-small-4 w-col-tiny-6">
                        <div>Last Update</div>
                    </div>
                    <div class="column w-col w-col-6 w-col-small-8 w-col-tiny-6 data">
                        <!--<div>{{request.Thread[0].Contact.first}} {{request.Thread[0].Contact.last}} {{request.Thread[0].date | timeAgo}}</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-out-callout">
            <h3 class="slide-out-callout-heading">Questions</h3>
            <div class="custom-question-holder" v-for="extra in request.extras">
                <div><strong>{{ extra.name }}</strong></div>
                <div>{{ extra.value }}</div>
            </div>
        </div>
        <div class="interaction" v-for="t in request.Thread" :class="t.Contact.role">
            <div class="interaction-details">
                <div class="date">{{t.date | formatDateTime}}</div>

            </div>
            <div class="interaction-text">
                <h3 class="interaction-heading"> {{t.Contact.first}} {{t.Contact.last}} </h3>

                <p><span v-if="t.content">{{t.content}}</span>
                <span v-if="t.label !== 'message'" class="subdued">
                    <span v-if="t.content"><br /><br /></span>
                    <em>status updated to <strong>{{t.label}}</strong></em></span>
                </p>


                <span class="" v-if="t.Uploads" v-for="u, i in t.Uploads ">

                    <a target="_blank" :href="u.src" class="maintenance-upload">
                        <img v-if="/(\jpg|\jpeg|\png|\gif)$/i.test(u.extension)" :src="u.src" class="maintenance-upload-image" />
                        <div class="w-button secondary-btn" v-else >
                            <span class="icon"></span> &nbsp;{{u.name}}
                        </div>
                    </a>
                </span>

            </div>
        </div>

        <div class="add-interaction-section">
            <div class="w-form add-interaction-form">
                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

                <h3 class="interaction-heading">Update Maintenance Request</h3>
                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                        <div>Message</div>
                    </div>
                    <div class="form-input-container" :class="{'has-error': errors.has('message') }">
                        <textarea
                                name="message"
                                v-model="message"
                                v-validate="'required|max:1000'"
                                class="interaction-textarea w-input">

                        </textarea>
                        <span v-show="errors.has('message')" class="status-block error-block">{{ errors.first('message') }}</span>
                    </div>
                </div>
                <div class="horizontal-form-row" v-if="isAdmin">
                    <div class="horizontal-form-label">
                        <div>Update Status</div>
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input" style="width:100%">
                            <dropdown
                            :options="statuses"
                            v-model="status"
                            labelField="name"
                            idField="name"
                            id="name"
                            >

                                <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.data.name}}<br />
                                        <span class="subdued">{{props.data.description}}</span>

                                    </span>
                                </template>
                            </dropdown>
                        </div>
                    </div>
                </div>
                <div class="horizontal-form-row">
                    <div class="horizontal-form-label">
                        <div>Add Files</div>
                    </div>
                    <div class="form-input-container">
                        <div class="horizontal-form-input"  style="width: 100%;">

                        <v-file-input multiple v-model="files"></v-file-input>

<!--                            <span @click="$refs.file.click()" class="w-button btn-sm secondary-btn"><span class="icon"></span>&nbsp; Upload Files</span>-->

<!--                            <input style="visibility: hidden;" type="file" id="file" multiple ref="file" @change="fileAdded()"/>-->

<!--                            <div v-for="file in files" class="file-added tertiary-btn">-->
<!--                                {{ file.name }}-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row" v-if="isAdmin">
                    <div class="horizontal-form-label">
                        <div>Send Email to</div>
                    </div>
                    <div class="form-input-container" v-if="request.Lease">

                        <div class="checkbox" v-for="t in request.Lease.Tenants" :key="t.id">
                            <input
                                    type="checkbox"
                                    v-model="toEmail"
                                    :value="t.Contact.id"
                                    :id="'checkbox-tenant-email-' + t.id"
                                    :name="'checkbox-tenant-email-' + t.id"
                                    :data-name="'checkbox-tenant-email-' + t.id"
                                    class="w-checkbox-input">

                            <label
                                    :for="'checkbox-tenant-email-' + t.id"
                                    class="w-form-label">{{t.Contact.first}} {{t.Contact.last}} - {{t.Contact.email}}</label>
                        </div>
                    </div>
                </div>

                <div class="horizontal-form-row" v-if="isAdmin">
                    <div class="horizontal-form-label">
                        <div>Send Text message to</div>
                    </div>
                    <div class="form-input-container" v-if="request.Lease">

                        <div class="checkbox" v-for="t in request.Lease.Tenants" :key="t.id" v-if="t.Contact.Phones.length">
                            <div v-for="p in t.Contact.Phones" v-show="p.sms">
                                <input
                                        type="checkbox"
                                        v-model="toText"
                                        :value="p.id"
                                        :id="'checkbox-tenant-phone-' + p.id"
                                        :name="'checkbox-tenant-phone-' + p.id"
                                        :data-name="'checkbox-tenant-phone-' + p.id"
                                        class="w-checkbox-input">

                                <label
                                        :for="'checkbox-tenant-phone-' + p.id"
                                        class="w-form-label">{{t.Contact.first}} {{t.Contact.last}} - {{p.phone | formatPhone}} ({{p.type}})</label>
                            </div>
                        </div>

                    </div>
                </div>


                <div v-if="isAdmin">

                    <div class="horizontal-form-row">
                        <div class="horizontal-form-label">
                            <label>&nbsp;</label>
                        </div>
                        <div class="form-input-container">
                            <div class="horizontal-form-input">
                                <div class="checkbox">
                                    <input
                                            type="checkbox"
                                            v-model="todo.add"
                                            id="checkbox-todo"
                                            name="checkbox-todo"
                                            data-name="checkbox-todo"
                                            class="w-checkbox-input">

                                    <label
                                            for="checkbox-todo"
                                            class="w-form-label">Schedule A Reminder to Follow Up
                                    </label>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="horizontal-form-row"  v-if="todo.add">
                        <div class="horizontal-form-label">
                            <label>Remind Me On</label>
                        </div>
                        <div class="form-input-container" >
                            <div class="horizontal-form-input" :class="{'has-error': errors.has('date') }">
                                <datepicker
                                        name="date"
                                        format="MM/dd/yyyy"
                                        style="width: 125px;"
                                        v-model="todo.date"
                                        wrapper-class="date-wrapper"
                                        :disabled-dates="disabledDates"
                                        input-class="input light w-input date-input" ></datepicker>
                            </div>
                            <span v-if="remindTime">
                                <div class="horizontal-form-input">at</div>
                                <div class="horizontal-form-input" :class="{'has-error': errors.has('HH') }">
                                    <input placeholder="HH" id="HH" name="HH" v-model="todo.hh" v-validate="'required|numeric|between:1,12'" class="input light w-input" style="width: 55px"/></div>
                                <div class="horizontal-form-input">:</div>
                                <div class="horizontal-form-input" :class="{'has-error': errors.has('MM') }">
                                    <input v-model="todo.mm" v-validate="'required|numeric|min_value:0|max_value:59'" placeholder="MM" id="MM" name="MM" class="input light w-input" style="width: 55px" /></div>
                                <div class="horizontal-form-input">
                                    <dropdown
                                            forceChoice
                                            :options="['AM', 'PM']"
                                            v-model="todo.ampm"
                                            style="width:75px"
                                    >
                                    </dropdown>
                                </div>

                                <button @click="remindTime = !remindTime" class="w-button tertiary-btn">x</button>

                                <span
                                    v-show="errors.has('date') || errors.has('HH') || errors.has('MM')"
                                    class="status-block error-block">
                                    Please fix the reservation date and time
                                </span>
                            </span>
                            <span v-else>
                                <button @click="remindTime = !remindTime" class="w-button primary-btn remind-me">Set Time</button>
                            </span>
                        </div>


                    </div>


                    <div class="horizontal-form-row"  v-if="todo.add">
                        <div class="horizontal-form-label">
                            <label>Reminder Notes</label>
                        </div>

                        <div class="form-input-container" >
                            <div class="horizontal-form-input" style="width: 400px;" :class="{'has-error': errors.has('notes') }">
                                <textarea
                                    v-model="todo.details"
                                    id="notes"
                                    name="notes"
                                    class="w-input input light"
                                    rows="4"
                                    v-validate="'required|max:1000'"
                                    placeholder="Enter details about this reminder"
                                >
                                </textarea>

                                <span v-show="errors.has('notes')" class="status-block error-block">{{ errors.first('notes') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </template>
    <template v-slot:actions>
        <hb-btn :disabled="isLoading($options.name)" @click="saveMessage" color="primary">Update</hb-btn>
    </template>
    </hb-modal>
</template>


<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import UpdateStatus from './UpdateMaintenanceStatus.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import { EventBus } from '../../EventBus.js';
    import Datepicker from 'vuejs-datepicker';
    import { mapGetters } from 'vuex';
    export default {
        name: "MaintenanceRequests",
        data() {
            return {
                maintenance:{},
                message: null,
                reminder: null,
                toEmail:[],
                toText:[],
                status: null,
                ampm: 'AM',
                todo: {
                    add: false,
                    date: null,
                    hh: '',
                    mm: '',
                    ampm: 'AM',
                    details: ''
                },
                showEdit: false,
                contactMethods: {
                    sms: [],
                    email: []
                },

                options: {
                    url: 'https://api.leasecaptain.xyz/v1/uploads/save',
                    headers: {}
                },
                request:{},
                statuses: [
                    {
                        name: 'Open',
                        description: "This maintenance request is still ongoing"
                    },
                    {
                        name: 'On Hold',
                        description: "This maintenance request is not resolved, but there is no further action to take now."
                    },
                    {
                        name: 'Closed',
                        description: "This maintenance request is closed without resolution. The customer is unresponsive, or there was nothing to resolve."
                    },
                    {
                        name: 'Resolved',
                        description: "This maintenance request is resolved. The customer's issue has been solved."
                    }
                ],
                files: [],
                remindTime: false
            }
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                getAuthHeader: 'authenticationStore/getAuthHeader'
            }),
            disabledDates(){
                return {
                    to: moment().startOf('day').toDate()
                }
            },
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },

        },
        created(){

            if(this.maintenance_id){
                this.request.id = this.maintenance_id;
                this.fetchData();
            }
            if(this.selected){
              this.request = JSON.parse(JSON.stringify(this.selected));
            }


            var paths = location.hostname.split('.');

            // if(paths[1] + '.' + paths[2] == 'leasecaptain.xyz'){
            //     this.options.url = 'https://api.leasecaptain.xyz/v1/maintenance/' + this.id;
            // } else if(paths[1] + '.' + paths[2] == 'leasecaptain.com'){
            //     this.options.url = 'https://api.leasecaptain.com/v1/uploads/save'
            // }

            this.options.url = process.env.VUE_APP_API_PROTOCOL + '://' + process.env.API_SUBDOMAIN + '.' + process.env.DOMAIN + ':' + process.env.API_PORT + '/v1/maintenance' + this.id;

            var subdomain = paths[0];

            // TODO FIX PATHS
            this.options.headers = {
                "Authorization":  this.getAuthHeader,
                'Access-Control-Allow-Origin': 'https://' + subdomain +'.leasecaptain.com',
                'Referrer-Policy': 'origin-when-cross-origin'
            };

            var now = moment();
            this.todo.hh = '08';
            this.todo.mm = '00';
            this.todo.ampm = 'AM';
            this.todo.date = now.add(1, 'day').startOf('day').toDate();

            EventBus.$on('priority_action', this.priorityAction);

        },
        destroyed(){
            EventBus.$off('priority_action', this.priorityAction);
        },
        components:{
            Modal,
            UpdateStatus,
            Loader,
            Status,
            Dropdown,
            Datepicker
        },
        filters:{

            formatDateTime(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY HH:mm:ss');
            },
            formatDate(value){
                if(!value) return null;
                return moment(value).format('MM/DD/YYYY');
            },
            timeAgo(time){
                if(!time) return '';
                return moment(time).fromNow();
            }
        },
        methods: {
            fileAdded(evt){
                this.files = this.$refs.file.files;
            },
            priorityAction(){
                this.$emit('close');
            },
            updateStatus(data){
                var data = {
                    status: data.status,
                    content: data.content,
                    source: 'User Account',
                    maintenance_id: this.maintenance.id
                };
                this.validate(this).then(status => {
                    if(!status) return;
                    return api.put(this, api.MAINTENANCE_UPDATE_STATUS, data).then(r => {
                        this.showEdit = false;
                        this.fetchData();
                    });
                })
            },
            saveMessage(){
                var data = {
                    email: this.toEmail,
                    text: this.toText,
                    content: this.message,
                    source: "user account",
                    status: this.status
                };
                if(this.toEmail.length == 0){
                    this.errorSet(this.$options.name, "Please select recipient(s) who will receive this maintenance update.");
                    return;
                }
                this.validate(this).then(status => {
                    if(!status) return;
                    return api.postFile(this, api.MAINTENANCE + this.request.id + '/message', data, this.files).then(r => {
                        this.fetchData();
                        this.saveTodo(r.id);
                    });
                });
            },

            saveTodo(maintenance_id){

                if(!this.todo.add || !this.isAdmin) {
                    this.resetForm();
                    return;
                }

                var hours = this.todo.ampm == 'PM'? +this.todo.hh + 12 : this.todo.hh;
                var start = moment(moment(this.todo.date).format('YYYY-MM-DD') + ' ' + hours + ":" + this.todo.mm, 'YYYY-MM-DD HH:mm');

                var todo = {
                    start_date: moment(start).toDate(),
                    end_date: moment(start).toDate(),
                    details: this.todo.details,
                    activity_object_id: 'orKv43Bd74',
                    object_id: maintenance_id
                }

                this.validate(this).then(status => {
                    if(!status) return;
                    return api.post(this, api.TODOS,  todo).then(r => {
                        this.resetForm();
                        this.fetchData();
                    });
                })

            },
            resetForm(){
                this.message = null;
                this.reminder= null;
                this.toEmail = [];
                this.toText = [];
                this.upload =null;
                this.status = null;
                this.ampm = 'AM';
            },
            fetchData(){
                api.get(this, api.MAINTENANCE  + this.maintenance_id).then(r => {
                    this.request = r.maintenance;
                });
            },

            deleteMessage(){
                api.delete(this, api.MAINTENANCE, this.$route.params.maintenance_id).then(r => {
                    this.$router.replace('/messages');
                });
            },
            closeDropdown(){
                //TODO JQUERY FIX
               // $(event.target).closest('.dropdown-menu').dropdown('toggle');
            }
        },
        props:[
            'selected',
            'maintenance_id',
            'value'
        ],
        watch:{
            selected(selected){
                this.request = JSON.parse(JSON.stringify(selected));
                this.resetForm();
            },
            maintenance_id(id){

                this.request = {
                    id: id
                }
                this.resetForm();
                this.fetchData();
            },
            '$route': function(){
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

    .file-added{
        padding: 5px;
        display: block;
        margin-top: 5px;
    }

    .maintenance-upload-image{
        height: 60px;
        display: inline-block;
        border: 2px solid #dce8ef;
        padding: 5px;
        background: white;
    }
    a.maintenance-upload {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px
    }
    .remind-me{
        margin-bottom: 9px;
    }
</style>

